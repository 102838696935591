<template>
    <!-- Sidebar -->
    <div id="sidebar">
        <nav class="active">
            <div v-if="this.role === this.authorizer || this.role === this.approver || this.role === this.operator || this.role === this.searcher">
                <p>{{ $t('user.title') }}</p>
                <ul>
                    <li>
                        <router-link to="/users">{{ $t('user.list.title') }}</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.role === this.authorizer || this.role === this.operator">
                <p>{{ $t('payments.title') }}</p>
                <ul>
                    <li>
                        <router-link to="/paypal/transactions">{{ $t('payments.list.title') }}</router-link>
                    </li>
                    <li>
                        <router-link to="/paypal/subscriptions">{{ $t('payments.listPayPal') }}</router-link>
                    </li>
                    <li>
                        <router-link to="/paypal/plans">{{ $t('plans.plansOfPayPal') }}</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.role === this.authorizer || this.role === this.approver || this.role === this.operator || this.role === this.searcher">
                <p>{{ $t('kyc.titleMore') }}</p>
                <ul>
                    <li>
                        <router-link to="/kycs">{{ $t('kyc.list.title') }}</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.role === this.authorizer || this.role === this.approver || this.role === this.operator || this.role === this.searcher">
                <p>{{ $t('token.transactions.list.urlTitle') }}</p>
                <ul>
                    <li>
                        <router-link to="/token/transactions">{{ $t('token.transactions.list.title') }}</router-link>
                    </li>
                </ul>
            </div>
            <!--
            <p>{{ $t('tokens.title') }}</p>
            <ul>
                <li>
                    <router-link :to="{name:'tokenTransactions'}">{{ $t('token.transactions.list.urlTitle') }}</router-link>
                </li>
                <li>
                    <router-link :to="{name:'tokenBalances'}">{{ $t('token.balances.list.urlTitle') }}</router-link>
                </li>
                <li>
                    <router-link to="/history">{{ $t('balance.history.list.title') }}</router-link>
                </li>
                <li>
                    <router-link to="/log">{{ $t('log.list.title') }}</router-link>
                </li>
                <li>
                    <router-link to="/bonus">{{ $t('bonus.list.head') }}</router-link>
                </li>
                <li>
                    <router-link to="/reward">{{ $t('reward.list.title') }}</router-link>
                </li>
            </ul>
            -->

            <!-- 一時的に隠します。
            <p>{{ $t('forthing.title') }}</p>
            <ul>
                <li>
                    <router-link to="/miningSettings">{{ $t('mining.title') }}</router-link>
                </li>
            </ul>
            -->
            <div v-if="this.role === this.authorizer || this.role === this.approver || this.role === this.operator || this.role === this.searcher">
                <p>{{ $t('system.title') }}</p>
                <ul>
                    <li>
                        <router-link to="/monitoring">{{ $t('system.batchTitle') }}</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.role === this.authorizer || this.role === this.approver || this.role === this.operator || this.role === this.searcher">
                <p>{{ $t('payout.title') }}</p>
                <ul>
                    <li>
                        <router-link to="/payout">{{ $t('payout.specialPayout') }}</router-link>
                    </li>
                </ul>
            </div>
            <div v-if="this.role === this.authorizer || this.role === this.approver || this.role === this.operator || this.role === this.searcher">
                <p>{{ $t('forthingContracts.title') }}</p>
                <ul>
                    <li>
                        <router-link to="/forthingContracts">{{ $t('forthingContracts.forthingContracts') }}</router-link>
                    </li>
                </ul>
            </div>
        </nav>
        <div v-if="isLogged" class="mt-5">
            <router-link to="/" @click.native="handleOnClickLogout()">{{$t('auth.logout')}}</router-link>
        </div>
    </div>
</template>

<script>
    import LanguageChoice from './LanguageChoice/LanguageChoice.vue';
    import AdminService from "../services/AdminService";
    import AuthUtil from '../utils/AuthUtil';
    import { AUTHORIZER, SEARCHER, OPERATOR, APPROVER } from '../utils/AuthUtil';

    export default {
        name: 'sidebar',

        data() {
            return {
                isLogged: true,
                role: 0,
                authorizer: 0,
                searcher: 0,
                operator: 0,
                approver: 0
            }
        },

        components: {
            LanguageChoice
        },

        methods: {
            handleOnClickLogout(e) {
                AdminService.logout();
                this.isLogged = false;
                this.$router.go('');
            },
        },

        mounted() {
            this.authorizer = AUTHORIZER;
            this.searcher = SEARCHER; 
            this.operator = OPERATOR;
            this.approver = APPROVER;
            // cookieからroleを取得
            this.isAuth = AuthUtil.getAuth();
            const admin_user_info = JSON.parse(this.isAuth);
            this.role = Number(admin_user_info.role);
        }
    }
</script>

<style>
    #sidebar {
        width: 250px;
        min-height: 100vh;
        padding: 20px;
        border-right: 1px solid #c2bec6;
    }

    #sidebar.active {
        /*margin-left: -250px;*/
    }
</style>
